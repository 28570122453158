@import 'css/_reset.css';
@import 'css/_colours.css';
@import 'css/_fonts.css';
@import 'css/_dimensions.css';
@import 'css/_typography.css';

/* ensure that everything is full height and no overflow */
html, body, #root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }