.workspace {
    display: flex;
    width: 100%;
    height: 100%;
    
    flex-direction: column;
    flex-grow: 1;

    box-sizing: border-box;
    z-index: 100;

    background-color: var(--workspace-bg);
}

.workspaceLoader {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--workspace-bg);
}

.workspaceFrame {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    background-color: var(--workspace-bg);
}

.workspace header {
    display: flex;
    padding: 0.3rem 0.5rem;
    flex-direction: row;
    align-items: center;
    background: var(--workspace-header-bg);
    box-sizing: border-box;
    border-bottom: 1px solid var(--workspace-header-border-color);
    color: var(--workspace-header-color);
    font-size: var(--workspace-header-font-size);
}

.workspace header > h1 {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: var(--workspace-header-font-size);
    line-height: 0;
}

.workspace header button {
    display: block;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    color: var(--workspace-header-color);
}

.workspace main {
    flex-grow: 1;
}