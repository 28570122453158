.App {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  background-color: var(--body-bg);
}

.AppContent {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.AppContent > main {
  flex-grow: 1;
}