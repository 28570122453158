:root {
    /**************************************
    Core colour swatch variables for the
    brand; these can be customised. The 
    number denotes saturation.
    **************************************/
    --white: #f7f7f7;
    --black: #06080a;

    --red-20: #f4cfd5;
    --red-40: #e89fab;
    --red-60: #de6f81;
    --red-80: #d23f57;
    --red-100: #c8102e;

    --green-20: #00ff00;    /* TODO */
    --green-40: #00ff00;    /* TODO */
    --green-60: #00ff00;    /* TODO */
    --green-90: #00ff00;    /* TODO */
    --green-100: #00ff00;   /* TODO */

    --blue-20: #e0f0f9;
    --blue-40: #c0e1f4;
    --blue-60: #a0d1ef;
    --blue-80: #81c4ea;
    --blue-100: #62b5e5;

    --yellow-20: #ffff00;    /* TODO */
    --yellow-40: #ffff00;    /* TODO */
    --yellow-60: #ffff00;    /* TODO */
    --yellow-90: #ffff00;    /* TODO */
    --yellow-100: #ffff00;   /* TODO */

    --orange-20: #fbe8cc;
    --orange-40: #f7d099;
    --orange-60: #f4b965;
    --orange-80: #efa132;
    --orange-100: #ed8b00;

    --teal-20: #cce7ed;
    --teal-40: #9acfda;
    --teal-60: #65b8c8;
    --teal-80: #34a0b7;
    --teal-100: #0089a4;

    --purple-20: #e9d4e7;
    --purple-40: #d2acd0;
    --purple-60: #be85bc;
    --purple-80: #a95ba5;
    --purple-100: #93328e;

    --indigo-20: #2200aa;   /* TODO */
    --indigo-40: #2200aa;   /* TODO */
    --indigo-60: #2200aa;   /* TODO */
    --indigo-80: #2200aa;   /* TODO */
    --indigo-100: #2200aa;  /* TODO */

    --gray-20: #d3d5d8;
    --gray-40: #a7acb0;
    --gray-60: #7a8289;
    --gray-80: #4f5962;
    --gray-100: #24303b;
}

/**************************************
Specific application color usage.
**************************************/

@media (prefers-color-scheme: light) {
    :root {
        --body-bg: var(--white);
        --body-color: var(--black);

        --navbar-bg: var(--blue-100);
        --navbar-color: var(--black);
        --navbar-hover-bg: var(--blue-80);
        --navbar-color-alt: var(--red-100);
        --navbar-active-bg: var(--blue-80);
        --navbar-active-border: var(--gray-100);
        --navbar-separator-border: var(--gray-20);
        --navbar-hover-shadow-color: rgba(255, 255, 255, 0.65);
        --navbar-hover-shadow-size: 12px;
    
        --context-bg: var(--gray-80);
        --context-color: var(--white);
        --context-title-color: var(--gray-20);
        --context-subtitle-color: var(--gray-20);
        --context-hover-bg: var(--gray-100);
        --context-hover-color: var(--white);
        --context-border-color: var(--gray-60);
        --context-icon-color: var(--blue-60);

        --menu-grid-bg: var(--white);
        --menu-grid-color: var(--gray-100);
        --menu-grid-hover-bg: var(--orange-60);
        --menu-grid-hover-color: var(--gray-100);
        --menu-grid-border-color: var(--gray-40);
        
        --navbar-icon-primary-color: var(--red-100);
        --navbar-icon-secondary-color: var(--white);
        --navbar-icon-primary-opacity: 1;
        --navbar-icon-secondary-opacity: 1;

        --workspace-bg: var(--white-100);
        --workspace-color: var(--gray-100);
        --workspace-header-bg: var(--teal-100);
        --workspace-header-color: var(--white);
        --workspace-header-border-color: var(--purple-80);

        --header-bg: var(--black);
        --header-border-color: var(--gray-80);

        --search-box-bg: var(--gray-100);
        --search-box-color: var(--white);
        --search-box-placeholder-color: var(--gray-40);
        --search-box-active-bg: var(--black);
        --search-box-active-border-color: var(--orange-100);

        --navbar-scroll-bg: var(--gray-20);
        --navbar-scroll-color: var(--gray-40);
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --body-bg: var(--gray-100);
        --body-color: var(--gray-20);

        --navbar-bg: var(--black);
        --navbar-color: var(--white);
        --navbar-hover-bg: var(--orange-80);
        --navbar-color-alt: var(--red-100);
        --navbar-active-bg: var(--orange-100);
        --navbar-active-border: var(--orange-60);
        --navbar-separator-border: var(--gray-80);
        --navbar-hover-shadow-color: var(--orange-20);
        --navbar-hover-shadow-size: 12px;
    
        --context-bg: var(--gray-80);
        --context-color: var(--white);
        --context-title-color: var(--gray-20);
        --context-subtitle-color: var(--gray-20);
        --context-hover-bg: var(--gray-100);
        --context-hover-color: var(--white);
        --context-border-color: var(--gray-60);
        --context-icon-color: var(--blue-60);

        --menu-grid-bg: var(--gray-100);
        --menu-grid-color: var(--gray-40);
        --menu-grid-hover-bg: var(--orange-60);
        --menu-grid-hover-color: var(--gray-100);
        --menu-grid-border-color: var(--orange-80);
        
        --navbar-icon-primary-color: var(--orange-100);
        --navbar-icon-secondary-color: var(--white);
        --navbar-icon-primary-opacity: 1;
        --navbar-icon-secondary-opacity: 0.8;
        
        --navbar-icon-active-primary-color: var(--gray-100);
        --navbar-icon-active-secondary-color: var(--white);
        --navbar-icon-active-primary-opacity: 1;
        --navbar-icon-active-secondary-opacity: 1;

        --workspace-bg: var(--gray-100);
        --workspace-color: var(--gray-20);
        --workspace-header-bg: var(--purple-100);
        --workspace-header-color: var(--white);
        --workspace-header-border-color: var(--purple-80);

        --header-bg: var(--black);
        --header-border-color: var(--gray-80);

        --search-box-bg: var(--gray-100);
        --search-box-color: var(--white);
        --search-box-placeholder-color: var(--gray-40);
        --search-box-active-bg: var(--black);
        --search-box-active-border-color: var(--orange-100);

        --navbar-scroll-bg: var(--gray-100);
        --navbar-scroll-color: var(--gray-60);
    }
}