.navbarItem {
    display: block;
}

.navbarItem.open {
    background-color: var(--navbar-hover-bg);
}

.navbarItem > a {
    display: flex;
    flex-direction: column;
    width: var(--navbar-width);
    height: calc(var(--navbar-width) * 0.85);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: inherit;
    text-decoration: none !important;

    box-sizing: border-box;
    transition: all 250ms ease;
}

    .navbarItem > a:hover,
    .navbarItem > a:focus {
        background-color: var(--navbar-hover-bg);
        background: linear-gradient(45deg, var(--orange-100), var(--orange-80));
    }

    .navbarItem > a > i {
        margin-bottom: 0.25rem;
        font-size: calc(var(--navbar-width) * 0.4);
    }

    .navbarItem > a:hover i {
        text-shadow: 0 0 var(--navbar-hover-shadow-size) var(--navbar-hover-shadow-color);
    }

    .navbarItem > a > span {
        font-size: var(--navbar-font-size);
        display: none; /* TODO: do we need to put this back? */
    }

    .navbarItem > a.active,
    .navbarItem > a:focus {
        background-color: var(--navbar-active-bg);
    }

/* FontAwesome color overrides */
.navbarItem {
    --fa-primary-color: var(--navbar-icon-primary-color);
    --fa-secondary-color: var(--navbar-icon-secondary-color);
    --fa-primary-opacity: var(--navbar-icon-primary-opacity);
    --fa-secondary-opacity: var(--navbar-icon-secondary-opacity);
}