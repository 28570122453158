.resultItem {
    margin-bottom: var(--padding-small);
}

.resultLink:link,
.resultLink:visited {
    text-decoration: none;
}

.resultLink:hover {
    color: var(--orange-100) !important;
}

.resultText {
    color: var(--white);
}

.resultLink i {
    margin-right: 0.5rem;
    color: var(--blue-20);
}