.launcher {
    display: flex;
    flex-direction: column;

    width: var(--navbar-width);
    height: 100%;

    background: linear-gradient(45deg, var(--navbar-bg), transparent);
    color: var(--navbar-color);
}

.fixedItems {
    border-top: 1px solid var(--navbar-separator-border);
}
    
.pinnedItems {
    flex-grow: 1; /* the middle set of links should expand forcing the rest to the bottom */
    /* hack for now to accommodate overflowing menu */
    overflow-x: hidden;
    overflow-y: auto;
}

.pinnedItems::-webkit-scrollbar {
    width: var(--navbar-scroll-width);
}
  
.pinnedItems::-webkit-scrollbar-track {
    background: var(--navbar-scroll-bg);
}
  
.pinnedItems::-webkit-scrollbar-thumb {
    background-color: var(--navbar-scroll-color);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid var(--navbar-scroll-bg);
}
