.dialog {
    top: 50px;

    width: 900px;
    height: 500px;

    background: var(--body-bg);
    color: var(--body-color);

    border-radius: 0.5rem;
    border: none;
    
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);

    z-index: 9999;
}