.user {
    display: flex;
    height: var(--header-height);
    padding: 0 var(--padding-small);
    align-items: center;
    border: none;
    font-size: 1rem;
}

.username {
    color: var(--gray-60);
}

.active {
    background-color: var(--gray-100);
}