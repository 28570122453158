@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
  
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.results {
    position: absolute;
    
    top: calc(var(--search-box-height) + 5px);
    left: 0;

    padding: 1.5rem;

    box-sizing: border-box;

    width: 100%;

    background: var(--gray-100);
    border-radius: 4px;

    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
    
    animation-duration: 300ms;
    animation-name: fadeInDown;

    z-index: 9999;
}