:root {
    --hr-height: 1px;
    --hr-color: var(--gray-40);
}

body {
    font-family: var(--font-family-base);
    font-size: var(--font-size-base);
    color: var(--body-color);
}

p, li, blockquote, address, dt, dd {
    line-height: var(--line-height-base);
}

p {
    margin-bottom: var(--paragraph-break-base);
}

h1, h2, h3 {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: var(--paragraph-break-base);
}

a:link, a:visited {
    color: var(--link-color);
    text-decoration: underline;
}

a:hover {
    color: var(--link-color-hover);
}

a:focus {
    outline: none;
}

hr {
    border: none;
    height: var(--hr-height);
    background-color: var(--hr-color);
}

.sr-only {
    display: none;
}