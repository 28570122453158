.header {
    display: flex;
    height: var(--header-height);
    align-items: center;
    align-content: center;

    background-color: var(--header-bg);
    border-bottom: 1px solid var(--header-border-color);
}

.left {
    width: 20rem;
}

.center {
    flex-grow: 1;
    padding: 0 var(--padding-small);
}

.right {
    display: flex;
    align-items: center;
}

.right > div {
    margin-right: var(--padding-small);
}

.right button {
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    color: inherit;
    font-size: 24px;
    font-family: inherit;
}