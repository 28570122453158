.welcomePanel {
    width: 100%;
    height: 100%;
    background-image: url(./splash.svg);
    background-size: auto 100%;
    background-position: top left;
    background-repeat: no-repeat;
    border-left: 1px solid var(--gray-40);
}

.inner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: top;
    justify-content: right;
    opacity: .75;
}

.content {
    opacity: 1;
    max-width: 45%;
    margin-right: 2rem;
    text-align: right;
    font-weight: 100;
    color: var(--workspace-color);
    font-size: 4rem;
}