.wrapper {
    position: relative;
    
    display: flex;
    align-items: center;
    height: var(--search-box-height);
    padding-left: var(--padding-small);
    padding-right: var(--padding-small);

    background: var(--search-box-bg);
    border-radius: 4px;
    
    transition: all 200ms ease;
}

.search {
    display: inline-block;
    flex-grow: 1;
    height: var(--search-box-height);
    margin-left: var(--padding-small);
    
    font-family: inherit;
    
    border: none;
    background: transparent;
    
    color: var(--search-box-color);
}

.search:focus {
    outline: none;
    border: none;
}

.search::placeholder {
    color: var(--search-box-placeholder-color);
}

.focus {
    background-color: var(--search-box-active-bg);
    outline: 2px solid var(--search-box-active-border-color);
}