@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.grid {
    position: fixed;
    left: var(--navbar-width);

    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(auto-fit, var(--menu-item-size));
    
    width: calc(var(--menu-item-size) * 4);
    
    background: var(--menu-grid-bg);
    color: var(--menu-grid-color);

    outline: 2px solid var(--menu-grid-border-color);
    box-shadow: 4px 3px 6px rgba(0, 0, 16, 0.5);

    z-index: 9998;

    animation-duration: 95ms;
    animation-fill-mode: both;
    animation-name: fadeIn;
}
    
    li.menuItem {
        display: block;
        height: var(--menu-item-size);
    }
    
    a.menuLink {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: var(--context-color);
        transition: all 185ms ease;
    }

    a.menuLink:hover,
    a.menuLink:focus {
        background-color: var(--menu-grid-hover-bg);
        color: var(--menu-grid-hover-color);
    }

    a.menuLink i {
        font-size: 1.875rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .menuText {
        display: block;
        margin-bottom: 1rem;
        font-size: 0.6rem;
        text-align: center;
        opacity: .8;
        line-height: 1.3em;
    }

    a.menuLink:hover .menuText {
        opacity: 1;
    }
    