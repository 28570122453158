:root {
    /* generic dimensions and measures */
    --padding-small: 0.5rem;
    --line-height-base: 1.45em;
    --paragraph-break-base: 0.75em;

    /* Left-hand navigation menu */
    --navbar-width: 68px;             /* width of the left-hand strip */
    --navbar-font-size: 0.68rem;      /* font size for the left-hand strip and menu grid */
    --navbar-scroll-width: 10px;
    
    /* menu grid dimensions */
    --menu-item-size: calc(var(--navbar-width) * 1.25);
    --menu-item-font-size: 0.68rem;

    --header-height: 40px;
    --search-box-height: 28px;
    --header-height: 50px;
    --search-box-height: 36px;

    --logo-min-width: 250px;
    --logo-max-width: 350px;

    --workspace-header-font-size: 0.875rem;

    /* Context menus */
    --context-width: 12rem;             /* standard width of context menus */
    --context-padding-x: 0.75rem;
    --context-padding-y: 0.5rem;
}

@media (min-width: 1800px) {
    :root {
        --header-height: 50px;
        --search-box-height: 36px;
    }
}