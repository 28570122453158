/* bouncing in animation */
@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
  
    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }
  
    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
  
    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
  
    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }
  
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

.pinnedLink {
    /* FontAwesome color overrides */
    --fa-primary-color: var(--navbar-icon-primary-color);
    --fa-secondary-color: var(--navbar-icon-secondary-color);
    --fa-primary-opacity: var(--navbar-icon-primary-opacity);
    --fa-secondary-opacity: var(--navbar-icon-secondary-opacity);

    text-shadow: 2px 2px 6px rgba(0, 0, 0, .5);
    
    animation-duration: 450ms;
    animation-name: bounceIn;
}

.pinnedLink:hover,
.pinnedLink:focus {
    text-shadow: none;
    --fa-primary-color: var(--gray-100);
    
    --fa-primary-color: var(--navbar-icon-active-primary-color);
    --fa-secondary-color: var(--navbar-icon-active-secondary-color);
    --fa-primary-opacity: var(--navbar-icon-active-primary-opacity);
    --fa-secondary-opacity: var(--navbar-icon-active-secondary-opacity);
}

.active {
    background-color: var(--navbar-active-bg);
    
    --fa-primary-color: var(--navbar-icon-active-primary-color);
    --fa-secondary-color: var(--navbar-icon-active-secondary-color);
    --fa-primary-opacity: var(--navbar-icon-active-primary-opacity);
    --fa-secondary-opacity: var(--navbar-icon-active-secondary-opacity);
}

.open {
    border-left: 4px solid var(--navbar-active-border);
}