menu.context {
    position: fixed;
    z-index: 9999;

    min-width: var(--context-width);
    margin: 0;
    padding: 0;

    background: var(--context-bg);
    color: var(--context-color);

    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.4);
}

menu.context li {
    margin: 0;
    padding: 0;
    list-style: none;
}

menu.context li.title {
    padding: var(--context-padding-y) var(--context-padding-x);
    font-weight: bold;
    color: var(--context-title-color);
}

menu.context li.subtitle {
    padding: var(--context-padding-y) var(--context-padding-x);
    padding-top: 0;
    font-weight: 100;
    font-size: 0.875em;
    color: var(--context-subtitle-color);
    border-bottom: 1px solid var(--context-border-color);
}

menu.context button {
    display: block;
    width: 100%;
    padding: var(--context-padding-y) var(--context-padding-x);
    border: none;
    background: transparent;
    color: inherit;
    font-size: 0.875rem;
    cursor: pointer;
    opacity: .8;
    text-align: left;
    border-bottom: 1px solid var(--context-border-color);
}

menu.context li:last-child button {
    border-bottom: none;
}

menu.context button i {
    margin-right: 0.75em;
    color: var(--context-icon-color);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

menu.context button:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
}

menu.context button:hover i {
    text-shadow: 0 0 5px var(--context-icon-color);
}